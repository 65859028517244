import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {observer} from "mobx-react-lite";
import Banner from "../resource/Banner.jpg";
import Service from "../resource/Service.png";
import BannerEn from "../resource/BannerEn.jpg";
import ServiceEn from "../resource/ServiceEn.png";
import languageStore from "../store/Language.Store";

function Page2() {
    return (
        <div style={{width:"100%",maxWidth:1300,margin:"auto"}}>
            <img src={languageStore.isEnglish ? BannerEn : Banner} alt="" style={{width: "100%", maxWidth: 1300}}/>
            <img src={languageStore.isEnglish ? ServiceEn : Service} alt="" style={{width:"100%",maxWidth:1300}}/>
        </div>
    );
}

export default observer(Page2);
