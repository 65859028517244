import './App.css';
import {Suspense} from "react";
import {unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate} from "react-router-dom";
import history from "./main/history.js";
import NotFound from "./main/404.js";
import Main from "./main/Main.js";
import Home from "./main/Home.js";
import Page1 from "./main/Page1.js";
import Page2 from "./main/Page2.js";
import Page3 from "./main/Page3.js";
import Page4 from "./main/Page4.js";
import Page5 from "./main/Page5.js";
import Page6 from "./main/Page6.js";
import Page7 from "./main/Page7.js";


function App() {
    return (
        <div className="App">
            <Suspense fallback={<div></div>}>{/*for now, not to show any loading tip*/}
                <HistoryRouter history={history}>
                    <Routes>
                        <Route path="/" element={<Main/>}>
                            <Route index element={<Home/>}/>
                            <Route path="/1" element={<Page1/>}/>
                            <Route path="/2" element={<Page2/>}/>
                            <Route path="/3" element={<Page3/>}/>
                            <Route path="/4" element={<Page4/>}/>
                            <Route path="/5" element={<Page5/>}/>
                            <Route path="/6" element={<Page6/>}/>
                            <Route path="/7" element={<Page7/>}/>
                        </Route>
                        <Route path="/*" element={<NotFound/>}/>
                    </Routes>
                </HistoryRouter>
            </Suspense>
        </div>
    );
}

export default App;
