import React, {useEffect, useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import {Layout, Menu,} from 'antd';
import {GlobalOutlined} from '@ant-design/icons';
import {observer} from "mobx-react-lite";
import "./Main.css";
import HeaderBackground from '../resource/HeaderBackground.jpg';
import Logo from "../resource/Logo.png";
import FooterContact from '../resource/FooterContact.png';
import FooterContactEn from '../resource/FooterContactEn.png';
import languageStore from "../store/Language.Store.js";


function Main() {
    const {Header, Content, Footer} = Layout;
    const navbarItems = [
        {label: <Link to="/">{languageStore.isEnglish ? "Home" : "首頁"}</Link>, key: 'home',},
        {label: <Link to="/1">{languageStore.isEnglish ? "Overview" : "風水概述"}</Link>, key: '1',},
        {label: <Link to="/2">{languageStore.isEnglish ? "Service" : "風水服務"}</Link>, key: '2',},
        {label: <Link to="/3">{languageStore.isEnglish ? "Functions" : "風水作用"}</Link>, key: '3',},
        {label: <Link to="/4">{languageStore.isEnglish ? "Arrangement" : "風水佈置"}</Link>, key: '4',},
        {label: <Link to="/5">{languageStore.isEnglish ? "Procedure" : "工作程序"}</Link>, key: '5',},
        {label: <Link to="/6">{languageStore.isEnglish ? "Health" : "健康與風水"}</Link>, key: '6',},
        {label: <Link to="/7">{languageStore.isEnglish ? "Law" : "官司與風水"}</Link>, key: '7',},
    ];

    return (
        <div>
            <Layout>
                <Header className={"header"} style={{
                    backgroundColor: "#ece6d6",
                    padding: 0,
                    height: 105,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        width: "100%",
                        maxWidth: 1125,
                        backgroundColor: "grey",
                        position: "relative",
                        overflow: "hidden"
                    }}>
                        <img src={Logo} alt="" style={{position: "absolute", height: 105, marginLeft: 20}}/>
                        <img src={HeaderBackground} style={{height: 105, width: 1300}} alt=""/>
                        <div style={{
                            position: "absolute",
                            right: 20,
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: 20,
                            cursor: "pointer",
                            // color:"black"
                        }}
                             onClick={() => {
                                 languageStore.setIsEnglish(!languageStore.isEnglish);
                             }}
                        >
                            <GlobalOutlined style={{marginRight: 5}}/>
                            {languageStore.isEnglish ? "Chinese" : "English"}
                        </div>
                    </div>


                </Header>
                <Header style={{backgroundColor: "#ebd18d", display: 'flex', justifyContent: 'center', padding: 0}}>
                    <Menu theme={"dark"} defaultSelectedKeys={['home']}
                          mode="horizontal"
                        // items={navbarItems.map(item => ({...item, style: {color: "#000", margin: "0 25px"}}))}
                          items={navbarItems.map(item => ({...item, style: {color: "#000"}}))}
                          style={{
                              // backgroundColor: "#ebd18d",
                              fontSize: languageStore.isEnglish ? "clamp(9px, 2vw, 21px)" : "clamp(10px, 2vw, 21px)",
                              width: "100%",
                              maxWidth: 1125,
                          }}
                    />
                </Header>

                <Content style={{backgroundColor: "#ece6d6"}}><Outlet/></Content>
                <Footer style={{backgroundColor: "#ece6d6", padding: 0}}>
                    <img src={languageStore.isEnglish ? FooterContactEn : FooterContact} alt=""
                         style={{width: "100%", maxWidth: 1300}}/>
                </Footer>
            </Layout>
        </div>
    );
}

export default observer(Main);
