import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {observer} from "mobx-react-lite";
import HomeBanner from '../resource/HomeBanner.jpg';
import HomeIntroduction from "../resource/HomeIntroduction.png";
import HomeBannerEn from '../resource/HomeBannerEn.jpg';
import HomeIntroductionEn from "../resource/HomeIntroductionEn.png";
import languageStore from "../store/Language.Store";



function Home() {
    return (
        <div style={{width: "100%", maxWidth: 1300, margin: "auto"}}>
            <img src={languageStore.isEnglish?HomeBannerEn:HomeBanner} alt="" style={{width: "100%", maxWidth: 1300}}/>
            <img src={languageStore.isEnglish?HomeIntroductionEn:HomeIntroduction} alt="" style={{width: "100%", maxWidth: 1300}}/>
        </div>
    );
}

export default observer(Home);
