import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {observer} from "mobx-react-lite";
import Banner from "../resource/Banner.jpg";
import Procedure from "../resource/Procedure.png";
import BannerEn from "../resource/BannerEn.jpg";
import ProcedureEn from "../resource/ProcedureEn.png";
import languageStore from "../store/Language.Store";

function Page5() {
    return (
        <div style={{width:"100%",maxWidth:1300,margin:"auto"}}>
            <img src={languageStore.isEnglish ? BannerEn : Banner} alt="" style={{width: "100%", maxWidth: 1300}}/>
            <img src={languageStore.isEnglish ? ProcedureEn : Procedure} alt="" style={{width:"100%",maxWidth:1300}}/>
        </div>
    );
}

export default observer(Page5);
