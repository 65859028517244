import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {observer} from "mobx-react-lite";
import Banner from "../resource/Banner.jpg";
import Health from "../resource/Health.png";
import BannerEn from "../resource/BannerEn.jpg";
import HealthEn from "../resource/HealthEn.png";
import languageStore from "../store/Language.Store";

function Page6() {
    return (
        <div style={{width:"100%",maxWidth:1300,margin:"auto"}}>
            <img src={languageStore.isEnglish ? BannerEn : Banner} alt="" style={{width: "100%", maxWidth: 1300}}/>
            <img src={languageStore.isEnglish ? HealthEn : Health} alt="" style={{width:"100%",maxWidth:1300}}/>
        </div>
    );
}

export default observer(Page6);
