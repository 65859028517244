import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {observer} from "mobx-react-lite";
import Banner from "../resource/Banner.jpg";
import Arrangement from "../resource/Arrangement.png";
import BannerEn from "../resource/BannerEn.jpg";
import ArrangementEn from "../resource/ArrangementEn.png";
import languageStore from "../store/Language.Store";


function Page4() {
    return (
        <div style={{width: "100%", maxWidth: 1300, margin: "auto"}}>
            <img src={languageStore.isEnglish ? BannerEn : Banner} alt="" style={{width: "100%", maxWidth: 1300}}/>
            <img src={languageStore.isEnglish ? ArrangementEn : Arrangement} alt="" style={{width: "100%", maxWidth: 1300}}/>
        </div>
    );
}

export default observer(Page4);
