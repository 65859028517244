import React, {useEffect, useState} from 'react';
import {Layout} from 'antd';
import {observer} from "mobx-react-lite";
import Banner from "../resource/Banner.jpg";
import Case from "../resource/Case.png";
import BannerEn from "../resource/BannerEn.jpg";
import CaseEn from "../resource/CaseEn.png";
import languageStore from "../store/Language.Store";

function Page7() {
    return (
        <div style={{width:"100%",maxWidth:1300,margin:"auto"}}>
            <img src={languageStore.isEnglish ? BannerEn : Banner} alt="" style={{width: "100%", maxWidth: 1300}}/>
            <img src={languageStore.isEnglish ? CaseEn : Case} alt="" style={{width:"100%",maxWidth:1300}}/>
        </div>
    );
}

export default observer(Page7)
