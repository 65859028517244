import {makeAutoObservable} from "mobx";

class LanguageStore {
    language = "cn";
    isEnglish = false

    constructor() {
        makeAutoObservable(this);
    }

    setLanguage(language) {
        this.language = language;
    }

    setIsEnglish(isEnglish) {
        this.isEnglish = isEnglish;
    }
}

const languageStore = new LanguageStore();
export default languageStore;
